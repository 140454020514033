<template>
<div>
    <div class="d-flex mb-4" style="">
        <div class="avo-page-header-text">
            Code Reference
        </div>
    </div>
        
    <b-card-group deck>
        <b-card 
            title="ICD/diagnosis codes"
            footer="Source: NIH, National Library of Medicine"
            style="max-width: 600px; min-height: 400px;">
            <icd-auto-complete />
        </b-card>
        <b-card 
            title="CPT/procedure codes"
            style="max-width: 600px; min-height: 400px;">
            <cpt-auto-complete />
        </b-card>
    </b-card-group>
</div>
</template>

<script>
import ICDAutoComplete from '../components/ICDAutoComplete.vue'
import CPTAutoComplete from '../components/CPTAutoComplete.vue'

export default {
  name: 'CodeLookup',
  components: {
      icdAutoComplete: ICDAutoComplete,
      cptAutoComplete: CPTAutoComplete
  },
}
</script>
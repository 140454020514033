<template>
<div>
    <div id="lookup">
    <b-input-group class="search-group">
        <b-input-group-prepend>
            <span class="input-group-text"><b-icon icon="search"></b-icon></span>
        </b-input-group-prepend>
        <b-input
            id="cpt-code"
            class="code-search-input"
            placeholder="Start typing a code or code description"
            v-model="search" @keyup="update" />
    </b-input-group>
    </div>
    <ul id="display" v-if="results.length > 0" class="ml-4 mt-2">
        <li v-for="result in results" :key="result.code">
            <strong>{{result.code}}</strong> - {{result.medium}}
        </li>
    </ul>
</div>
</template>

<script>
import { make_url, authenticated_request } from '../common.js'

export default {
  name: 'CPTAutoComplete',
  data: function(){
      return {
          results: [],
          search: ""
      }
  },
  methods: {
      update: function(){
            var vm = this;
            const url = make_url("/reports/codes/autocomplete");
            authenticated_request({
                method: "get",
                url: url,
                params: {
                    search: this.search,
                    limit: 7
                }
            }).then(function(response){
                const results = response.data.codes;
                vm.results = results || [];
                return results || [];
            }).catch(function(errors){
                console.log(errors.response)
            });
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::v-deep .search-group {
    position: relative;
    margin-right: 20px;
    border: 1px solid #C9D2DF;
    border-radius: 5px;
    max-width:300px;
}
::v-deep .code-search-input, .input-group-text {
    height:50px;
    border: none;
    border-radius: 5px;
    background: white;
    color: #9CA3AD; 
}
::v-deep .code-search-input::placeholder {
    color: #9CA3AD;
    font-size:12px;
    opacity: 1;
}

</style>
<template>
<div>
    <div id="lookup">
    <!-- <label for="cpt-code">Code Lookup</label> -->
    <b-input-group class="search-group">
        <b-input-group-prepend>
            <span class="input-group-text"><b-icon icon="search"></b-icon></span>
        </b-input-group-prepend>
        <b-input
            id="cpt-code"
            class="code-search-input"
            placeholder="Start typing a code or code description"
            v-model="search" @keyup="update" />
    </b-input-group>
    </div>
    <ul id="display" v-if="hasResults" class="ml-4 mt-2">
        <li v-for="result in results" :key="result[0]">
            <strong>{{result[0]}}</strong> - {{result[1]}}
        </li>
    </ul>
</div>
</template>

<script>
import axios from "axios";

export default {
  name: 'ICDAutoComplete',
  data: function(){
      return {
          hasResults: false,
          results: [],
          search: ""
      }
  },
  methods: {
      update: function(){
            var self = this;
            console.log(this.search);
            axios.get(
                "https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search",
                {
                    params: { 
                        sf: "code,name",
                        terms: self.search
                    }
                }
            ).then(
                function(response) {
                    self.results = response.data[3];
                    self.hasResults = true;
            }).catch(
                function(error){
                    console.log(error);
            });
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::v-deep .search-group {
    position: relative;
    margin-right: 20px;
    border: 1px solid #C9D2DF;
    border-radius: 5px;
    max-width:300px;
}
::v-deep .code-search-input, .input-group-text {
    height:50px;
    border: none;
    border-radius: 5px;
    background: white;
    color: #9CA3AD; 
}
::v-deep .code-search-input::placeholder {
    color: #9CA3AD;
    font-size:12px;
    opacity: 1;
}

</style>